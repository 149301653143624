import React, { useState } from 'react';
import FileUpload from 'components/common/FileUpload';
import { Button } from '@mui/material';
import { uploadCSV } from 'services/UploadService';
import styled from 'styled-components';
import { Card } from 'react-bootstrap';

export const Uploads = () => {
    const uploadRef = React.createRef<any>();
    const [fileName, setFileName] = React.useState<any>('');
    const [fileContent, setFileContent] = React.useState<any>('');

    const handleUpload = (file: File) => {
        setFileName(file?.name);
        uploadCSV(fileName, file);
    };

    // const onReaderLoad = async (event) => {
    //     setFileContent(event.currentTarget.result);
    //     startUpload();
    // };

    // const startUpload = async () => {
    //     await uploadCSV(fileName, fileContent);
    // };

    return (
        <>
            <FormWrapper>
                <FormCard>
                    <div ref={uploadRef}>
                        <FileUpload extensions={['csv']} onChange={handleUpload} onError={alert}>
                            <div
                                style={{
                                    alignItems: 'center',
                                }}>
                                <h3>Upload your File Here</h3>
                                <Button variant="outlined" key={'uploadCSV'} style={{ marginRight: '0' }}>
                                    Upload
                                </Button>
                                {/* {logo && logo.name && (
                                        <span style={{ marginLeft: '10px', marginRight: '10px' }}>{logo.name}</span>
                                    )} */}
                            </div>
                        </FileUpload>
                    </div>
                </FormCard>
            </FormWrapper>
        </>
    );
};
export default Uploads;

const FormWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
`;

const FormCard = styled(Card)`
    margin: 1em;
    padding: 1.5em;
    min-width: 80vw;
    max-width: 700px;
    .alert-success,
    .button-icon-right {
        display: none;
    }
`;
